export const PAGE = {
  home: "/",
  authCheck: "/auth/check",
  login: "/auth/login",
  logout: "/auth/logout",
  dashboard: "/dashboard",
  mySites: "/dashboard/my-sites",
  addSitePlan: "/dashboard/add-site-plan",
};

export const SERVER_ENDPOINT =
  window.location.host.includes("webflow-ext.com")
    ? `https://optomate-apiprod-v2.optomate.ai/`
    : window.location.host.includes("webflow.io")
    ? `https://optomate-apiprod-v2.optomate.ai/`
    : window.location.host.includes("optomate.ai")
    ? `https://optomate-apiprod-v2.optomate.ai/`
    : `http://localhost:4001`;

export const GQL_ENDPOINT = `${SERVER_ENDPOINT}/api/graphql`;

export const CLIENT_ID =
  "b095e8d12ae408b47214ac1b8829fd1abe18791cffb93ff9e68617f81b235acc";
